import React, { Component } from 'react';
import Sidebar from './SidebarUa.js';


export default class MenuUa extends Component {
    render() {
        return (
<li className="hamburger"><Sidebar  outerContainerId={'outer-container'} /></li>     
  );
}

}




