import React, { Component } from 'react';
import Sidebar from './SidebarPl.js';


export default class MenuPl extends Component {
    render() {
        return (
<li className="hamburger"><Sidebar  outerContainerId={'outer-container'} /></li>     
  );
}

}




